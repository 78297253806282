import {useEffect} from "react";
import {v4 as uuidv4} from "uuid";

type UseMiniProfilerProps = {
    isProductionEnvironment: boolean;
    rootUrl: string;
    accessToken?: Promise<string | undefined> | string | undefined;
}

export const useMiniProfiler = (props: UseMiniProfilerProps) => {
    const {isProductionEnvironment, rootUrl, accessToken} = props;

    useEffect(() => {
        //we don't want to show the mini profiler in production
        if (isProductionEnvironment) {
            return;
        }

        const path = `${rootUrl}/mini-profiler-resources/`;

        const version = uuidv4();

        const script = document.createElement("script");
        script.async = true;
        script.src = `${path}includes.min.js?v=${version}`;
        script.type = "text/javascript";
        script.id = "mini-profiler";
        script.setAttribute(
            "data-css-url",
            `${path}includes.min.css?v=${version}`
        );
        script.setAttribute("data-version", version);
        script.setAttribute("data-path", path);
        script.setAttribute("data-position", "Left");
        script.setAttribute("data-scheme", "auto");
        script.setAttribute("data-authorized", "true");
        script.setAttribute("data-trivial", "false");
        script.setAttribute("data-children", "false");
        script.setAttribute("data-max-traces", "20");
        script.setAttribute("data-controls", "true");
        script.setAttribute("data-total-sql-count", "true");
        script.setAttribute("data-toggle-shortcut", "Alt+P");
        script.setAttribute("data-start-hidden", "false");
        script.setAttribute("data-collapse-results", "true");
        script.setAttribute("data-hidden-custom-fields", "");
        script.setAttribute("data-html-container", "body");

        document.head.appendChild(script);

        const {fetch: originalFetch} = window;
        window.fetch = async (resource: RequestInfo | URL, init?: RequestInit | undefined) => {
            let config = init;

            const url = resource instanceof Request ? resource.url : resource.toString();


            if (url.includes(path) && config) {
                if(typeof accessToken !== "undefined"){
                    const token = await accessToken;

                    config = {
                        ...config,
                        headers: {
                            ...config.headers,
                            Authorization: `Bearer ${token}`,
                        },
                    };
                } else {
                    config = {
                        ...config,
                        headers: {
                            ...config.headers,
                        },
                        credentials: "include",
                    };
                }
            }

            return originalFetch(resource, config);
        };

        return () => {
            window.fetch = originalFetch;
        };
    }, [accessToken]);
};
